@import "variables";
@import "../assets/dist/bootstrap/scss/bootstrap";

html {
  position: relative;
  min-height: 100%;
}

a:hover {
    text-decoration: none;
}

.wave {
  position: absolute;
}

.wave::before {
  position: relative;
  margin-right: -40px;
  content: '';
}

.intro:hover .wave {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation: wink 0.5s alternate infinite; /* Safari 4+ */
    -moz-animation:    wink 0.5s alternate infinite; /* Fx 5+ */
    -o-animation:      wink 0.5s alternate infinite; /* Opera 12+ */
    animation:         wink 0.5s alternate infinite; /* IE 10+, Fx 29+ */
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.42, 0.55, 0.85); /* Chrome, Safari, Opera */
  animation-timing-function: cubic-bezier(0.54, 0.42, 0.55, 0.85);
}

/* Chrome, Safari, Opera */
@-webkit-keyframes wink {
0% {-webkit-transform: rotate(-10deg);
-moz-transform: rotate(-10deg);
-o-transform: rotate(-10deg);
-ms-transform: rotate(-10deg);
}
100% {-webkit-transform: rotate(10deg);
-moz-transform: rotate(10deg);
-o-transform: rotate(10deg);
-ms-transform: rotate(10deg);
transform: rotate(10deg);}
}

/* Standard syntax */
@keyframes wink {
0% {-webkit-transform: rotate(-10deg);
-moz-transform: rotate(-10deg);
-o-transform: rotate(-10deg);
-ms-transform: rotate(-10deg);
transform: rotate(-10deg);
}
100% {-webkit-transform: rotate(10deg);
-moz-transform: rotate(10deg);
-o-transform: rotate(10deg);
-ms-transform: rotate(10deg);
transform: rotate(10deg);}
}

a.external > div > p > svg {
  opacity: 0;
  transform: rotate(-45deg);
}

a.external:hover > div > p > svg {
  opacity: 1;
  transition: opacity .4s;
}

pre.prettyprint.prettyprinted {
  border-radius: 4px;
  border: none;
  padding: 10px;
}

pre .nocode {
  background-color: none;
  color: #888888 !important;
}

.active {
  color: darken($site-color-primary, 20%);
  font-weight: 500;
}

body {
  margin-bottom: 60px;
  -webkit-tap-highlight-color: $site-color-primary;
}

.container {
  max-width: 600px;
}

.icon {
  color: $site-color-primary;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
}

#signup {
  .card {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.navbar {
  padding: 0.5rem 0rem;
}

.navbar-nav {
  margin-left: auto;
}

::-moz-selection {
  text-shadow: none;
  background: $site-color-primary;
}

::selection {
  text-shadow: none;
  background: $site-color-primary;
  color: #fff;
}

img::selection {
  background: 0 0;
}

img::-moz-selection {
  background: 0 0;
}

.fancybox-skin {
  margin-top: 60px !important;
}

.fancybox-title.fancybox-title-outside-wrap {
  margin-top: 0px;
}

.fancybox-title > nav.navbar.navbar-inverse.navbar-static-top {
  margin-bottom: 0;
  top: 0 !important;
  position: fixed;
  width: 100%;
  left: 0;
}

.previewParent {
  margin: 0px 10%;
  height: 60px;
}

.previewTitle {
  color: #e4eaec;
  padding: 17px 15px;
  font-size: 18px;
  line-height: 21px;
  height: 60px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.downloadAction {
  position: absolute;
  top: 0;
  right: 10px;
}

img.fancyimage {
  cursor: pointer;
}

img.fancybox {
  cursor: pointer;
}

.PageNavigation {
  display: block;
  width: auto;
  overflow: hidden;
  padding: 0px;
  margin: 30px 0px 30px;
  background-clip: border-box;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  div {
    color: #999;
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
    padding: 0px 15px;
  }
  p {
    margin: 0px;
    padding: 0px;
    font-size: .9rem;
    a {
      font-size: 1rem;
    }
  }
  .next {
    text-align: right;
  }
}


@media (min-width: 992px) {
  .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: space-between;
  }
  img.expanded {
    max-width: calc(100% + 150px) !important;
    margin-left: -75px;
    width: 100vw;
  }
}

blockquote {
  margin: 40px auto;
  width: 90%;
  p {
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    font-size: 22px;
    line-height: 34px !important;
  }
  .author {
    font-size: 18px;
    line-height: 25px;
    color: #707070;
  }
}